<template>
  <footer class="footer text-dark py-4 flex-grow-1">
    <div class="container">
      <div class="row">
        <!-- Company Information -->
        <div class="col-md-5 mb-2">
          <h5 class="mb-3">{{ $t('footer.companyName') }}</h5>
          <ul class="list-unstyled">
            <li class="mb-2">
              <i class="fas fa-building me-2"></i>
              {{ $t('footer.companyCode') }}
            </li>
            <li class="mb-2">
              <i class="fas fa-map-marker-alt me-2"></i>
              {{ $t('footer.address') }}
            </li>
            <li class="mb-2">
              <i class="fas fa-envelope me-2"></i>
              <a href="mailto:info@interstartas.lt">info@interstartas.lt</a>
            </li>
          </ul>
        </div>

        <!-- Bank Information -->
        <div class="col-md-5 mb-2">
          <h5 class="mb-3">{{ $t('footer.bankInfo') }}</h5>
          <ul class="list-unstyled">
            <li class="mb-2">
              <i class="fas fa-university me-2"></i>
              {{ $t('footer.bankName') }}
            </li>
            <li class="mb-2">
              <i class="fas fa-credit-card me-2"></i>
              {{ $t('footer.bankAccount') }}
            </li>
            <li class="mb-2">
              <i class="fas fa-code me-2"></i>
              {{ $t('footer.bankCode') }}
            </li>
          </ul>
        </div>

        <!-- Social Media Links -->
        <div class="col-md-2 mb-2">
          <h5 class="mb-3">{{ $t('footer.followUs') }}</h5>
          <div class="social-links">
            <a href="https://www.linkedin.com/company/interstartas" target="_blank" rel="noopener noreferrer">
              <i class="fab fa-linkedin fa-2x"></i>
            </a>
          </div>
        </div>
      </div>

      <!-- Copyright -->
      <div class="text-center mt-4 pt-3 border-top">
        <p class="mb-0">© {{ new Date().getFullYear() }} {{ $t('footer.companyName') }}</p>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer'
}
</script>

<style>
.footer {
  background-color: rgba(255, 255, 255, 0.95);
  backdrop-filter: blur(10px);
  box-shadow: 0 2px 3px 5px rgb(0 0 0 / 0.05);
  padding: 1rem 0;
}

.footer h5 {
  font-weight: 600;
  color: #333;
  text-transform: uppercase;
}

.footer a {
  color: inherit;
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer a:hover {
  color: #0d2c6e;
}

.social-links {
  display: flex;
  align-items: center;
}

.social-links a {
  color: #333;
  transition: color 0.3s ease;
}

.social-links a:hover {
  color: #0d2c6e;
}

.border-top {
  border-color: rgba(0, 0, 0, 0.05) !important;
}

@media (max-width: 768px) {
  .footer {
    text-align: center;
  }

  .social-links {
    justify-content: center;
    margin-bottom: 1rem;
  }
}
</style>