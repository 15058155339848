import { useGtag } from 'vue-gtag-next'

export function useAnalytics() {
    const { event } = useGtag()

    const trackPageView = (pageTitle, pagePath) => {
        event('page_view', {
            page_title: pageTitle,
            page_path: pagePath
        })
    }

    const trackEvent = (eventName, eventParams) => {
        event(eventName, eventParams)
    }

    const trackLanguageChange = (language) => {
        event('language_change', {
            language: language
        })
    }

    const trackContactFormSubmit = (formData) => {
        event('contact_form_submit', {
            form_data: formData
        })
    }

    return {
        trackPageView,
        trackEvent,
        trackLanguageChange,
        trackContactFormSubmit
    }
} 