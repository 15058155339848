import { createApp } from 'vue'
import './style.css'
import App from './App.vue'
import 'bootstrap/dist/js/bootstrap.bundle.min.js'
import i18n from './i18n/setup'
import VueGtag from 'vue-gtag-next'

// Initialize app with i18n
const app = createApp(App)

// Use i18n plugin
app.use(i18n)

// Initialize Google Analytics
app.use(VueGtag, {
    property: {
        id: 'G-V2QV25PVK3' // Replace with your Google Analytics ID
    }
})

// Mount app
app.mount('#app')

