<template>
  <div v-if="!hasConsent" class="cookie-consent">
    <div class="cookie-content">
      <p>{{ $t('cookies.message') }}</p>
      <div class="cookie-buttons">
        <button @click="acceptCookies" class="btn btn-primary me-2">
          {{ $t('cookies.accept') }}
        </button>
        <button @click="declineCookies" class="btn btn-outline-secondary">
          {{ $t('cookies.decline') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'

const { locale } = useI18n()
const hasConsent = ref(false)

const acceptCookies = () => {
  localStorage.setItem('cookieConsent', 'accepted')
  localStorage.setItem('language', locale.value)
  hasConsent.value = true
}

const declineCookies = () => {
  localStorage.setItem('cookieConsent', 'declined')
  hasConsent.value = true
}

onMounted(() => {
  hasConsent.value = localStorage.getItem('cookieConsent') !== null
})
</script>

<style scoped>
.cookie-consent {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.95);
  backdrop-filter: blur(10px);
  padding: 1rem;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.cookie-content {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}

.cookie-buttons {
  display: flex;
  gap: 0.5rem;
}

@media (max-width: 768px) {
  .cookie-content {
    flex-direction: column;
    text-align: center;
  }

  .cookie-buttons {
    width: 100%;
    justify-content: center;
  }
}
</style>