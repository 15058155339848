<template>
  <section id="home" class="hero-section d-flex align-items-center">
    <div class="container text-center hero-content">
      <h1 class="display-2 fw-bold mb-4">{{ $t('home.title') }}</h1>
      <!-- <p class="lead fs-3 mb-5">Innovative IT Solutions for Your Business Success </p> -->
      <div class="d-flex gap-3 justify-content-center">
        <a href="#services" class="btn btn-outline-light btn-lg text-center fixed-width-btn">{{ $t('home.button1')
          }}</a>
        <a href="#contact" class="btn btn-outline-light btn-lg text-center fixed-width-btn">{{ $t('home.button2') }}</a>
      </div>
    </div>
  </section>
</template>
<style>
.btn {
  padding: 10px 20px;
  text-transform: uppercase;
}

.btn-lg {
  font-size: 1.1rem !important;
}

.fixed-width-btn {
  min-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.fw-bold {
  font-weight: 400 !important;
  text-transform: uppercase;
}

.display-2 {
  font-size: 3.4rem !important;
  letter-spacing: 0.1rem !important;
}

@media (max-width: 576px) {
  .fixed-width-btn {
    min-width: 150px;
  }
}
</style>