<script setup lang="ts">
import { ref, onMounted, onUnmounted } from 'vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

// Partner logos data
const partners = ref([
  { id: 1, name: 'Partner 1', logo: '/images/partners/Siemens.png' },
  { id: 2, name: 'Partner 2', logo: '/images/partners/DetaliuTiekimas.png' },
  { id: 3, name: 'Partner 3', logo: '/images/partners/VIP.png' },
  { id: 4, name: 'Partner 4', logo: '/images/partners/AutoTuzai.png' },
])

// Animation control
const isPaused = ref(false)
const animationSpeed = ref(30) // pixels per second
const position = ref(0)
let animationFrameId: number | null = null
let lastTimestamp = 0

// Calculate the width of a single logo container
const logoWidth = 200 // width of each logo container
const logoMargin = 40 // total margin (left + right) for each logo
const totalLogoWidth = logoWidth + logoMargin

// Start the animation
const startAnimation = () => {
  if (animationFrameId) return
  
  const animate = (timestamp: number) => {
    if (!lastTimestamp) lastTimestamp = timestamp
    const deltaTime = timestamp - lastTimestamp
    
    if (!isPaused.value) {
      // Calculate how many pixels to move based on time and speed
      const pixelsToMove = (animationSpeed.value * deltaTime) / 1000
      position.value -= pixelsToMove
      
      // Calculate the total width of all logos
      const totalWidth = partners.value.length * totalLogoWidth
      
      // When the first set of logos has moved completely off screen
      if (Math.abs(position.value) >= totalWidth) {
        // Instead of resetting to 0, we'll subtract the total width
        // This creates a seamless loop without any visible jump
        position.value += totalWidth
      }
    }
    
    lastTimestamp = timestamp
    animationFrameId = requestAnimationFrame(animate)
  }
  
  animationFrameId = requestAnimationFrame(animate)
}

// Pause animation on hover
const pauseAnimation = () => {
  isPaused.value = true
}

// Resume animation when not hovering
const resumeAnimation = () => {
  isPaused.value = false
}

// Clean up animation on component unmount
onUnmounted(() => {
  if (animationFrameId) {
    cancelAnimationFrame(animationFrameId)
  }
})

// Start animation when component is mounted
onMounted(() => {
  startAnimation()
})

// Define the component for export
defineOptions({
  name: 'Partners'
})
</script>

<template>
  <section class="partners-section bg-interstartas py-5">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <h2 class="section-title text-center mb-5">{{ $t('partners.title') }}</h2>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="partners-container" 
               @mouseenter="pauseAnimation" 
               @mouseleave="resumeAnimation">
            <div class="partners-track" :style="{ transform: `translateX(${position}px)` }">
              <!-- First set of logos -->
              <div v-for="partner in partners" :key="partner.id" class="partner-logo">
                <img :src="partner.logo" :alt="partner.name" class="img-fluid">
              </div>
              
              <!-- Duplicate set for seamless scrolling -->
              <div v-for="partner in partners" :key="`dup-${partner.id}`" class="partner-logo">
                <img :src="partner.logo" :alt="partner.name" class="img-fluid">
              </div>
              
              <!-- Second duplicate set for extra smoothness -->
              <div v-for="partner in partners" :key="`dup2-${partner.id}`" class="partner-logo">
                <img :src="partner.logo" :alt="partner.name" class="img-fluid">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>
.partners-section {
  background-color: #f8f9fa;
  overflow: hidden;
  padding: 4rem 0;
}

.partners-container {
  position: relative;
  width: 100%;
  overflow: hidden;
  padding: 2rem 0;
}

.partners-track {
  display: flex;
  transition: transform 0.1s linear;
}

.partner-logo {
  flex: 0 0 200px;
  height: 100px;
  margin: 0 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: white; */
  border-radius: 8px;
  /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.partner-logo:hover {
  transform: translateY(-5px);
  /* box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1); */
}

.partner-logo img {
  max-width: 80%;
  max-height: 80%;
  object-fit: contain;
  filter: grayscale(100%);
  opacity: 0.7;
  transition: filter 0.3s ease, opacity 0.3s ease;
}

.partner-logo:hover img {
  filter: grayscale(0%);
  opacity: 1;
}
.bg-interstartas{
    /* background: #1f253c; */
    background: white;
}

/* Mobile-specific styles */
@media (max-width: 768px) {
  .partner-logo {
    flex: 0 0 150px;
    height: 80px;
    margin: 0 1rem;
  }
}
</style> 